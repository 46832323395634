import {_} from './translate-placeholder';

export enum LANGUAGE_TYPES {
    ENGLISH = 'ENGLISH',
    SLOVAK = 'SLOVAK',
}

export function nameForLanguageType(type: LANGUAGE_TYPES): string {
    switch (type) {
        case LANGUAGE_TYPES.ENGLISH:
            return _('Angličtina');
        case LANGUAGE_TYPES.SLOVAK:
            return _('Slovenčina');
        default:
            return _('Ostatné');
    }
}

export function isoCode2languageType(code: string): LANGUAGE_TYPES {
    switch (code) {
        case 'en':
            return LANGUAGE_TYPES.ENGLISH;
        case 'sk':
            return LANGUAGE_TYPES.SLOVAK;
        default:
            return LANGUAGE_TYPES.SLOVAK;
    }
}
