<div class="institution-section--header" *ngIf="group">

  <h3>
    {{ group | localizedParam: 'name' }}
  </h3>

  <button class="p-button p-button-secondary p-button-outlined show-more p-button-xs" (click)="toggleExpanded()">
    {{expanded ? ('show-less' | translate) : ('show-more' | translate)}}
  </button>

</div>

<div class="institution-section--apps" [class.expanded]="expanded" *ngIf="group && group.apps && group.apps.length > 0">
  <app-app-item
    *ngFor="let app of group.apps"
    [app]="app"
    [expanded]="expanded" [allowFavorite]="allowFavoriteAssign"></app-app-item>
</div>
