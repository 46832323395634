import {Component, EventEmitter, Input, Output} from '@angular/core';
import {InstitutionGroupModel} from '../../../models/institution-group.model';
import {NgIf} from '@angular/common';
import {AuthService} from '../../../services/auth.service';
import {AppGroupService} from '../../../services/app-group.service';
import {LocalizedParamPipe} from '../../../pipes/localized-param.pipe';

@Component({
  selector: 'app-sidebar-item',
  standalone: true,
  imports: [
    NgIf,
    LocalizedParamPipe,
  ],
  templateUrl: './sidebar-item.component.html',
  styleUrl: './sidebar-item.component.scss'
})
export class SidebarItemComponent {

  @Input() group: InstitutionGroupModel;

  @Input() index: number;

  @Output() clicked = new EventEmitter<InstitutionGroupModel>();

  constructor(
    protected appGroupService: AppGroupService
  ) {
  }

  clickedItem() {
    this.clicked.emit(this.group);
  }

  clickedReset(e: Event) {
    e.stopImmediatePropagation();
    this.appGroupService.resetFilteredGroups();
  }

}
