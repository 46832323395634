import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SidebarItemComponent} from './sidebar-item/sidebar-item.component';
import {AppGroupService} from '../../services/app-group.service';
import {AsyncPipe, NgForOf} from '@angular/common';
import {InstitutionGroupModel} from '../../models/institution-group.model';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    SidebarItemComponent,
    NgForOf,
    AsyncPipe,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {

  @Output() toggle = new EventEmitter<void>();

  @Input() canBeToggled = false;

  constructor(
    public appGroupService: AppGroupService
  ) {
  }

  toggleSidebar() {
    this.toggle.emit();
  }

  clickedSidebarItem(item: InstitutionGroupModel) {
    this.appGroupService.filterGroupsByGroup(item);

    if (this.canBeToggled) {
      this.toggleSidebar();
    }
  }
}
