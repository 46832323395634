<div class="sidebar-item"
     [class.active]="appGroupService.currentSelectedGroup === group"
     (click)="clickedItem()"
     *ngIf="group">

  <div class="index">
    {{ index + 1 }}
  </div>

  <div class="sidebar-item__name">
    {{ group | localizedParam: 'name' }}
  </div>

  <button class="p-button p-button-outlined p-button-sm pull-right" (click)="clickedReset($event)" *ngIf="appGroupService.currentSelectedGroup === group">
    <i class="fa fa-times"></i>
  </button>

</div>
