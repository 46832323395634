import {Injectable} from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {initTranslatePlaceholderFunction} from './translate-placeholder';
import {environment} from '../../environments/environment.dev';

@Injectable()
export class AppTranslationService {

    private _currentLanguage: string = '';

    private _changedLanguageSubject = new Subject<string>();

    constructor(
        private translateService: TranslateService,
    ) {
    }

    get changedLanguage(): Subject<string> {
        return this._changedLanguageSubject;
    }

    set currentLanguage(value: string) {
        this._currentLanguage = value;
    }

    get currentLanguage(): string {
        return this._currentLanguage;
    }

    get hasSavedLanguage(): boolean {
        const language = window.localStorage.getItem('language');
        return !!language;
    }

    switchLanguage(language: string): Promise<void> {

        if (language === this._currentLanguage) {
            return Promise.resolve();
        }

        if (environment.availableLanguages.indexOf(language) === -1) {
            return Promise.reject('Invalid language code: ' + language);
        }

        this._currentLanguage = language;

        window.localStorage.setItem('language', language);

        const asPromise = firstValueFrom(this.translateService.use(
            language,
        ));

        asPromise.then(
            () => {
                this._changedLanguageSubject.next(this._currentLanguage);
            },
        );

        return asPromise;

    }

    async initApp(): Promise<void> {
        let currentLanguage: string | null = '';

        if (window.localStorage.getItem('language')) {
            currentLanguage = window.localStorage.getItem('language');
        }

        if (!currentLanguage) {
            if (navigator.languages) {
                for (let testedLang of navigator.languages) {
                    testedLang = this.simplifyLanguageCode(testedLang);
                    if (environment.availableLanguages.indexOf(testedLang) !== -1) {
                        currentLanguage = testedLang;
                        break;
                    }
                }
            } else if (navigator.language && environment.availableLanguages.indexOf(this.simplifyLanguageCode(navigator.language)) !== -1) {
                currentLanguage = navigator.language;
            }
        }

        if (!currentLanguage) {
            currentLanguage = environment.defaultLocale;
        }

        this.translateService.setDefaultLang(
            environment.defaultLocale,
        );

        this._currentLanguage = currentLanguage;
        const returnedPromise = firstValueFrom(this.translateService.use(
            currentLanguage,
        ));

        initTranslatePlaceholderFunction(this.translateService);

        await this.switchLanguage(this._currentLanguage);

        return returnedPromise;
    }

    protected simplifyLanguageCode(code: string): string {
        return code.substring(0, 2);
    }
}
