import {Inject, Injectable} from '@angular/core';
import {UserModel} from '../models/user.model';
import {environment} from '../../environments/environment.dev';
import {HttpClient} from '@angular/common/http';
import {LoginResponseModel} from '../models/login-response.model';
import {CookieService} from 'ngx-cookie-service';
import {first, map, Observable, tap} from 'rxjs';
import {UserEmploymentModel} from '../models/user-employment.model';
import {DOCUMENT} from '@angular/common';
import {StringUtils} from '../utils/string-utils';
import {ExternalAuthenticationRequest} from '../models/external-authentication-request.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  isLoggedIn = false;

  authToken: string | null = null;

  loggedUserData: LoginResponseModel | null = null;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: any
  ) {
  }

  redirectToLogin() {
    const returnUrl = window.location.href;
    const url = `${environment.AUTH_URL}/sso/auth?retUrl=${returnUrl}`;
    window.open(url, '_self');
  }

  externalLogin(request: ExternalAuthenticationRequest): Observable<any> {
    const url = `${environment.AUTH_URL}/auth/login`;

    return this.http
      .post(url, request)
      .pipe(
        tap((response: any) => {
          this.doStuffAfterLogin(response);

        }),
      );
  }

  extractUserInfo(token: string): LoginResponseModel | null {
    if (!token) {
      return null;
    }

    // Decode the Base64URL encoded token
    const decodedTokenString = this.decodeBase64Url(token);

    try {
      const decodedToken = JSON.parse(decodedTokenString);

      if (decodedToken) {
        const loginResponse = {
          access_token: decodedToken.access_token,
          refresh_token: decodedToken.refresh_token,
          expires: decodedToken.expires,
          refresh_expires: decodedToken.refresh_expires,
          message: decodedToken.message,
          user: decodedToken.user,
          session_guid: decodedToken.session_guid,
        };

        this.doStuffAfterLogin(loginResponse);
        return loginResponse; // Ensure you return the login response
      }
    } catch (error) {
      console.error('Error parsing JSON from decoded token:', error);
    }

    return null;
  }

  decodeBase64Url(base64UrlString: string): string {
    // Step 1: Replace Base64URL characters with Base64 characters
    const base64 = base64UrlString.replace(/-/g, '+').replace(/_/g, '/');

    // Step 2: Add padding if necessary
    const paddedBase64 = base64.padEnd(base64.length + (4 - base64.length % 4) % 4, '=');

    // Step 3: Decode Base64 string using atob and handle UTF-8
    try {
      // Convert Base64 to binary string
      const binaryString = atob(paddedBase64);

      // Convert binary string to a UTF-8 decoded string
      const utf8String = decodeURIComponent(
        Array.from(binaryString)
          .map((char) => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );

      return utf8String;
    } catch (error) {
      console.error('Error decoding Base64URL string:', error);
      return '';
    }
  }

  doStuffAfterLogin(loginResponse: LoginResponseModel) {
    this.saveUserToLocalStorage(loginResponse);

    this.token = loginResponse.access_token;

    this.loggedUserData = loginResponse;

    this.isLoggedIn = true;

    return loginResponse;
  }

  doStuffAfterSessionInfo(user: UserModel) {
    this.getUserFromLocalStorage();

    if (this.loggedUserData) {
      this.loggedUserData.user = user;

      this.saveUserToLocalStorage(this.loggedUserData);

      this.isLoggedIn = true;
    }
  }

  logout() {
    let url = '';

    // check if user isExternal
    if (this.loggedUserData?.user.isExternal) {
      url = `${environment.AUTH_URL}/auth/logout`;

      this.http
        .get(url)
        .pipe(
          tap((response: any) => {
            this.doAfterLogout();

            // add to url action=logout and success=true
            const returnUrl = window.location.href;
            const url = `${returnUrl}?action=logout&success=true`;

            this.document.location.href = url;
          }),
        )
        .pipe(first())
        .subscribe();
    } else {
      const returnUrl = window.location.href;
      const url = `${environment.AUTH_URL}/sso/logout?retUrl=${returnUrl}&access_token=${this.token}`;
      window.open(url, '_self');
    }

  }

  doAfterLogout() {
    this.isLoggedIn = false;
    this.loggedUserData = null;
    this.removeUserFromLocalStorage();
  }

  refreshToken() {
    const url = `${environment.AUTH_URL}/auth/token`;

    this.http
      .get(url)
      .subscribe((response: any) => {
        console.log('response refreshtoken::', response);
      });
  }

  getSessionInfo(): Observable<UserModel> {
    const url = `${environment.API_URL}/auth/getCurrentUser`;

    return this.http.get<UserModel>(url).pipe(
      tap((response: UserModel) => {
        this.doStuffAfterSessionInfo(response);
      }),
    );
  }

  getEmployments(): Observable<UserEmploymentModel[]> {
    const url = `${environment.AUTH_URL}/auth/getEmployments`;

    return this.http
      .get<UserEmploymentModel[]>(url)
      .pipe(
        map((response: UserEmploymentModel[]) => response),
      );
  }

  changeEmployment(employmentCode: string): Observable<any> {
    const url = `${environment.AUTH_URL}/auth/changeEmployment`;

    return this.http
      .post(url, {
        employmentCode,
      })
      .pipe(
        map((response) => response),
      );

  }

  changeWorkplace(workplaceCode: string) {
    const url = `${environment.AUTH_URL}/auth/changeWorkplace`;

    this.http
      .post(url, {
        workplaceCode,
      })
      .subscribe((response: any) => {
        console.log('response changeWorkplace::', response);
      });
  }

  saveLoggedUser() {

  }

  set token(token: string | null) {
    this.authToken = token;
  }

  get token() {
    return this.authToken;
  }

  saveUserToLocalStorage(data: LoginResponseModel) {
    localStorage.setItem(`authuser`, JSON.stringify(data));

    this.saveUserCookie(this.loggedUserData);
  }

  removeUserFromLocalStorage() {
    localStorage.removeItem(`authuser`);
    this.cookieService.delete('auth_user', '/', this.getAuthBaseCookieDomain());
  }

  getUserFromLocalStorage() {
    const data = localStorage.getItem(`authuser`);

    if (data) {
      const user = JSON.parse(data) as LoginResponseModel;

      if (user && user.access_token) {
        this.token = user.access_token;
      }

      this.loggedUserData = user;
    }
  }

  private saveUserCookie(user: any): void {
    if (!user) return;

    user._fromCookie = true;
    const data = JSON.stringify(user);

    this.cookieService.set(
      environment.production ? 'auth_user' : 'devauth_user',
      data.toString(),
      undefined,
      '/',
      this.getAuthBaseCookieDomain(),
      environment.production,
    );
  }

  getAuthBaseCookieDomain(): string {
    if (environment.production) {
      return 'tuke.sk';
    } else if (environment.isLocal) {
      return 'localhost';
    }
    return 'tuke.sk';
  }

  storeRedirectUrlAfterLogin(redirectUrl: string) {
    localStorage.setItem('redirectUrlAfterLogin', redirectUrl);
  }

  getRedirectUrlAfterLogin(): string | null {
    return localStorage.getItem('redirectUrlAfterLogin');
  }

  removeRedirectUrlAfterLogin() {
    localStorage.removeItem('redirectUrlAfterLogin');
  }

  redirectTo(redirectUrl: string) {
    const sessionJson: string = JSON.stringify(this.loggedUserData);
    const user = StringUtils.isNullOrWhitespace(sessionJson) ? undefined : (sessionJson as string);

    this.document.location.href = redirectUrl + '?development=true&user=' + encodeURIComponent(user ?? '');
  }

}
