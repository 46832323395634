import { Component } from '@angular/core';
import {DialogModule} from 'primeng/dialog';
import {Button} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InstitutionSectionComponent} from '../institution-section/institution-section.component';
import {AppItemComponent} from '../app-item/app-item.component';
import {FavoriteAppsService} from '../../services/favorite-apps.service';
import {TranslateModule} from '@ngx-translate/core';
import {AppGroupService} from '../../services/app-group.service';
import {first} from 'rxjs';
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-favorites-dialog',
  standalone: true,
  imports: [
    BrowserAnimationsModule,
    DialogModule,
    Button,
    InputTextModule,
    InstitutionSectionComponent,
    AppItemComponent,
    TranslateModule,
    CdkDropList,
    CdkDrag,
  ],
  templateUrl: './favorites-dialog.component.html',
  styleUrl: './favorites-dialog.component.scss'
})
export class FavoritesDialogComponent {

  visible: boolean = false;

  constructor(
    public favoritesDialogService: FavoriteAppsService,
    public appGroupService: AppGroupService
  ) {
  }

  showDialog() {
    this.visible = true;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.favoritesDialogService.favoriteApps, event.previousIndex, event.currentIndex);
  }

  clickedCancel() {
    this.favoritesDialogService.resetFavoriteApps();
    this.visible = false;
  }

  clickedSave() {
    const apps = this.favoritesDialogService.favoriteApps.map(app => app.id);

    this.appGroupService
      .updateFavoriteApps(apps)
      .pipe(first())
      .subscribe(
        {
          next: v => console.log(v),
          error: e => console.error(e)
        }
      )

    this.visible = false;
  }

}
