<div class="bg-container"></div>

<div class="login-container">
  <div class="login-card">
    <div class="logo">
      <img src="/img/tuke-logo.png" alt="">
      Technická univerzita <br>v Košiciach
    </div>
    <div class="login-info">
      <p-messages severity="error">
        <ng-template pTemplate>
            <span>
              {{'external-login-info' | translate}}
            </span>
        </ng-template>
      </p-messages>
    </div>
    <div class="form-group">
      <input type="text" id="login" name="login" [placeholder]="'Prihlasovacie meno' | translate" [(ngModel)]="externalAuthenticationRequest.login" >
    </div>
    <div class="form-group">
      <input type="password" id="password" name="password" [placeholder]="'Heslo' | translate" [(ngModel)]="externalAuthenticationRequest.password">
    </div>
    <div class="login-btn">
      <button type="submit" (click)="clickedSubmit($event)"
              [disabled]="!externalAuthenticationRequest || (!externalAuthenticationRequest.login || !externalAuthenticationRequest.password) || externalAuthenticationRequest.login.trim().length === 0 || externalAuthenticationRequest.password.trim().length === 0"
      >{{ 'Prihlásenie' | translate }}</button>
    </div>
    <div class="footer-text">
      © 2024 Technická Univerzita v Košiciach - ÚVT
    </div>
  </div>
</div>

<p-toast />
