<router-outlet />

<div id="loading">

  <p-progressSpinner
    styleClass="white-spinner"
  ></p-progressSpinner>

  <div class="loading-text" id="loadingText">
  </div>

</div>
