<p-dialog
  header="Header" [styleClass]="'favorite-dialog'"
  [(visible)]="visible"
  [draggable]="false"
  [modal]="true">
  <ng-template pTemplate="header">
      <span class="font-bold white-space-nowrap">
                    {{ 'favorite-apps-dialog-header' | translate }}
                </span>
  </ng-template>

  <ng-container *ngIf="visible">

    <div class="favorite-dialog--apps" cdkDropList cdkDropListOrientation="mixed" (cdkDropListDropped)="drop($event)">

      @for (item of favoritesDialogService.favoriteApps; track item) {

        <div class="example-box" cdkDrag>

          <app-app-item [app]="item" [expanded]="false" [allowFavorite]="true"></app-app-item>

        </div>
      }

    </div>

    <app-institution-section
      *ngFor="let institution of appGroupService.$groups | async"
      [group]="institution"
      [expanded]="false"
      [allowFavoriteAssign]="true"></app-institution-section>

  </ng-container>

  <ng-template pTemplate="footer">

    <p-button
      [label]="'Cancel' | translate"
      [text]="true"
      severity="secondary"
      (click)="clickedCancel()" />

    <p-button
      [label]="'Save' | translate"
      [outlined]="true"
      severity="secondary"
      (click)="clickedSave()"
    />

  </ng-template>

</p-dialog>
