import {Component, OnInit} from '@angular/core';
import {AppTranslationService} from './i18n/app-translation.service';
import {AppThemeService} from './theme/app-theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  constructor(
    private appTranslationService: AppTranslationService,
    private themeService: AppThemeService
  ) {
  }

  ngOnInit() {

    this.loadApp();

  }

  async loadApp() {
    const res = await this.appTranslationService.initApp();

    this.themeService.initTheme();
  }

}
