<div class="app-item--container"
     [pTooltip]="(!expanded) ? appNameTooltip : ''"
     [tooltipPosition]="'top'"
     [class.expanded]="expanded" (click)="clickedAppItem($event)" *ngIf="app">

  <ng-template #appNameTooltip>

    <div class="flex align-items-center">

        {{ app | localizedParam: 'name' }}

    </div>

  </ng-template>

  <div class="app-item--type-icon">
    <i class="fa fa-th-large" *ngIf="app.icon === InstitutionGroupAppTypeEnum.IS" [pTooltip]="'app-type-is-tooltip' | translate"></i>
    <i class="fa fa-link" *ngIf="app.icon === InstitutionGroupAppTypeEnum.URL" [pTooltip]="'app-type-url-tooltip' | translate"></i>
  </div>

  <div class="app-item--badge"
       *ngIf="!allowFavorite && auth.isLoggedIn && app.ssoReady"
       [pTooltip]="tooltipContent"
       [class.with-sso]="app.ssoReady"
  >

      <i class="fa fa-sign-in"></i>

  </div>

  <div class="app-item--badge"
       *ngIf="allowFavorite && favoritesDialogService.isFavoriteApp(app)"
       [pTooltip]="tooltipContent"
       [class.with-sso]="favoritesDialogService.isFavoriteApp(app)"
  >

    <i class="fa fa-star"></i>

  </div>

  <ng-template #tooltipContent>

    <div class="flex align-items-center">

      <ng-container *ngIf="!allowFavorite">
        <span *ngIf="app.ssoReady">{{'sso-logged-in-tooltip' | translate}}</span>
        <span *ngIf="!app.ssoReady">{{'sso-not-logged-in-tooltip' | translate}}</span>
      </ng-container>

      <ng-container *ngIf="allowFavorite">
        <span *ngIf="favoritesDialogService.isFavoriteApp(app)">{{ 'Aplikácia pridaná medzi dôležité' | translate }}</span>
      </ng-container>

    </div>

  </ng-template>

  <div class="app-item--header">
    {{ app | localizedParam: 'code' }}
  </div>

    <div class="app-item--description" [@expandCollapse]="expanded ? 'expanded' : 'collapsed'">

      <div class="app-item--description--header">
        {{ app | localizedParam: 'name' }}
      </div>

      {{ app | localizedParam: 'description' }}

    </div>

</div>
