import {Component, Input} from '@angular/core';
import {AppItemComponent} from '../app-item/app-item.component';
import {InstitutionGroupModel} from '../../models/institution-group.model';
import {NgForOf, NgIf} from '@angular/common';
import {LocalizedParamPipe} from '../../pipes/localized-param.pipe';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-institution-section',
  standalone: true,
  imports: [
    AppItemComponent,
    NgForOf,
    NgIf,
    LocalizedParamPipe,
    TranslateModule,
  ],
  templateUrl: './institution-section.component.html',
  styleUrl: './institution-section.component.scss'
})
export class InstitutionSectionComponent {

  @Input() expanded = false;

  @Input() allowFavoriteAssign = false;

  @Input() group: InstitutionGroupModel;

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

}
