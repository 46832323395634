import {Component, OnInit} from '@angular/core';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {MenuItem, MenuItemCommandEvent} from 'primeng/api';
import {JsonPipe, NgClass, NgIf} from '@angular/common';
import {AuthService} from '../../services/auth.service';
import {UserEmploymentModel} from '../../models/user-employment.model';
import {first} from 'rxjs';

@Component({
  selector: 'app-user-info',
  standalone: true,
  imports: [
    TieredMenuModule,
    NgClass,
    JsonPipe,
    NgIf,
  ],
  templateUrl: './user-info.component.html',
  styleUrl: './user-info.component.scss'
})
export class UserInfoComponent implements OnInit {

  items: MenuItem[] | undefined;

  constructor(
    public auth: AuthService
  ) {
  }

  ngOnInit() {

    this.generateItems();

  }

  generateItems() {

    this.items = [];

    const employments: UserEmploymentModel[] = this.auth.loggedUserData?.user?.employments ?? [];

    employments.forEach(employment => {
      if (this.items) {
        this.items.push({
          label: (employment.facultyCode ?? '') + ' - ' + (employment.workCenterCode ?? ''),
          command: () => {
            this.changeEmployment(employment, employments);
          }
        });
      }
    });

    if (this.items) {
      this.items.push({
          separator: true
        },
        {
          label: 'Logout',
          icon: 'fa fa-fw fa-sign-out',
          command: () => {
            this.auth.logout();
          }
        })
    }
  }

  changeEmployment(employment: UserEmploymentModel, employments: UserEmploymentModel[]) {

    if (employments && employments.length > 1 && this.auth.loggedUserData?.user.employmentCode !== employment.employmentCode) {

      this.auth.changeEmployment(employment.employmentCode)
        .pipe(first())
        // .subscribe({
        //   next: (response) => {
        //     this.auth.loggedUserData.user = response.user;
        //     this.auth.saveUserToLocalStorage(this.auth.loggedUserData);
        //   },
        //   error: (e) => {
        //     console.log('error::', e);
        //   }
        // })
      ;

    }

  }

}
