import {Component, Input} from '@angular/core';
import {NgIf} from '@angular/common';
import {FavoriteAppsService} from '../../services/favorite-apps.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {InstitutionGroupAppModel} from '../../models/institution-group-app.model';
import {LocalizedParamPipe} from '../../pipes/localized-param.pipe';
import {TooltipModule} from 'primeng/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {AuthService} from '../../services/auth.service';
import {LoadingService} from '../../services/loading.service';
import {InstitutionGroupAppTypeEnum} from '../../constants/InstitutionGroupAppTypeEnum';

@Component({
  selector: 'app-app-item',
  standalone: true,
  imports: [
    NgIf,
    LocalizedParamPipe,
    TooltipModule,
    TranslateModule,
  ],
  templateUrl: './app-item.component.html',
  styleUrl: './app-item.component.scss',
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({
        height: '0px',
        overflow: 'hidden',
        opacity: 0
      })),
      state('expanded', style({
        height: '*',
        opacity: 1
      })),
      transition('collapsed => expanded', [
        animate('300ms ease-in-out')
      ]),
      transition('expanded => collapsed', [
        animate('50ms ease-in-out')
      ])
    ])
  ]
})
export class AppItemComponent {

  @Input() app: InstitutionGroupAppModel = new InstitutionGroupAppModel();

  @Input() expanded = true;

  @Input() allowFavorite = true;

  constructor(
    public favoritesDialogService: FavoriteAppsService,
    public auth: AuthService
  ) {
  }

  clickedAppItem(e: Event) {
    e.preventDefault();

    if (this.allowFavorite) {
      this.favoritesDialogService.toggleFavoriteApp(this.app);
      return;
    }

    LoadingService.show();

    // Open app in a new window
    const newWindow = window.open(this.app.url, '_self'); // Use '_blank' to open in a new tab

    // Check if the window opened successfully
    if (newWindow) {
      // Wait for the new window to load
      setTimeout(() => {
        LoadingService.hide();
      }, 3000);
    } else {
      // If the window couldn't be opened (e.g., popup blocker), hide the loading spinner
      LoadingService.hide();
    }
  }

  protected readonly InstitutionGroupAppTypeEnum = InstitutionGroupAppTypeEnum;
}
