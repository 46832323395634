<div class="container" [class.sidebar-hidden]="isSidebarHidden">

  <div class="header">
    <app-header (actionClicked)="headerActionClicked($event)"></app-header>
  </div>

  <div class="content">

    <div class="sidebar" [hidden]="isSidebarHidden">
      <app-sidebar (toggle)="toggleSidebar()" [canBeToggled]="isMobile || isSidebarHidden" ></app-sidebar>
    </div>

    <div class="main-content">

      <div class="notifications">

        <p-messages severity="info">
          <ng-template pTemplate>
            <span>
              {{'welcome-dashboard-message' | translate}} <a href="mailto:is@helpdesk.tuke.sk">is{{'@'}}helpdesk.tuke.sk</a>.
            </span>
          </ng-template>
        </p-messages>

        <p-messages severity="info" *ngIf="auth.isLoggedIn">
          <ng-template pTemplate>
            <span>
              {{'sso-dashboard-info-message' | translate}} <i class="fa fa-sign-in"></i> {{'sso-dashboard-info-message-2' | translate}}
            </span>
          </ng-template>
        </p-messages>

        <p-messages severity="info" *ngIf="favoriteAppsService.favoriteApps && favoriteAppsService.favoriteApps.length === 0 && auth.isLoggedIn">
          <ng-template pTemplate>
            <span>
              {{'favorite-apps-dashboard-message' | translate}} <i class="fa fa-star"></i>.
            </span>
          </ng-template>
        </p-messages>

      </div>

      <div class="institutions-list">

        <p-inputGroup [styleClass]="'mt-2'">

          <button pButton icon="fa fa-bars" class="p-button-outlined" severity="secondary" (click)="toggleSidebar()" *ngIf="isMobile || isSidebarHidden" > </button>

          <ng-container *ngIf="!isMobile || (isMobile && isSidebarHidden)">

            <input pInputText class="p-inputtext-lg" [placeholder]="'search-input-placeholder' | translate" #search (input)="appGroupService.filterGroupsApps(search.value)" />

            <button type="button" pButton icon="fa fa-times" class="p-button-outlined" (click)="appGroupService.resetFilteredGroups(); search.value = '';"></button>

          </ng-container>

        </p-inputGroup>

        <div class="institutions-list--fav-section" *ngIf="favoriteAppsService.favoriteApps && favoriteAppsService.favoriteApps.length > 0">

          <div class="institutions-list--fav-header">
            <h3>{{'favorite-apps-section-header' | translate}}</h3>
          </div>

          <div class="institutions-list--fav-apps">
            <app-app-item [expanded]="false" *ngFor="let app of favoriteAppsService.favoriteApps" [app]="app" [allowFavorite]="false"></app-app-item>
          </div>
        </div>



        <app-institution-section
          *ngFor="let institution of appGroupService.$filteredGroups | async"
          [group]="institution"
          [allowFavoriteAssign]="false"></app-institution-section>

      </div>

    </div>

  </div>

  <div class="footer">
    <app-footer></app-footer>
  </div>

</div>

<app-favorites-dialog></app-favorites-dialog>
