import { Injectable } from '@angular/core';
import {InstitutionGroupAppModel} from '../models/institution-group-app.model';

@Injectable({
  providedIn: 'root'
})
export class FavoriteAppsService {

  favoriteApps: InstitutionGroupAppModel[] = [];

  backFavoriteApps: InstitutionGroupAppModel[] = [];

  constructor() { }

  addFavoriteApp(app: InstitutionGroupAppModel) {
    this.favoriteApps.push(app);
  }

  removeFavoriteApp(app: InstitutionGroupAppModel) {
    this.favoriteApps = this.favoriteApps.filter(favoriteApp => favoriteApp.codeSK.toLowerCase().trim() !== app.codeSK.toLowerCase().trim());
  }

  getFavoriteApps() {
    return this.favoriteApps;
  }

  isFavoriteApp(app: InstitutionGroupAppModel) {
    // check it by codeSk
    const isApp = this.favoriteApps.find(favoriteApp => favoriteApp.codeSK.toLowerCase().trim() === app.codeSK.toLowerCase().trim());
    return isApp !== undefined;
  }

  setFavoriteApps(favoriteApps: InstitutionGroupAppModel[]) {
    this.favoriteApps = favoriteApps;
    this.backFavoriteApps = [...favoriteApps];
  }

  clearFavoriteApps() {
    this.favoriteApps = [];
  }

  toggleFavoriteApp(app: InstitutionGroupAppModel) {
    if (this.isFavoriteApp(app)) {
      this.removeFavoriteApp(app);
    } else {
      this.addFavoriteApp(app);
    }
  }

  resetFavoriteApps() {
    this.favoriteApps = [...this.backFavoriteApps];
  }

  getFavoriteAppsCount() {
    return this.favoriteApps.length;
  }

}
