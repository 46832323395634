import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.dev';
import {HttpClient} from '@angular/common/http';
import {InstitutionGroupModel} from '../models/institution-group.model';
import {map, Observable, of, tap} from 'rxjs';
import {AuthService} from './auth.service';
import {LoadingService} from './loading.service';
import {FavoriteAppsService} from './favorite-apps.service';
import {removeDiacriticsFromString} from '../misc/remove-diacritics';

@Injectable({
  providedIn: 'root',
})
export class AppGroupService {

  groups: InstitutionGroupModel[] = [];
  $groups: Observable<InstitutionGroupModel[]>;

  filteredGroups: InstitutionGroupModel[] = [];
  $filteredGroups: Observable<InstitutionGroupModel[]>;

  currentSelectedGroup: InstitutionGroupModel | null = null;

  url = `${environment.API_URL}/group`;
  favoriteAppsUrl = `${environment.API_URL}/favouriteapp`;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private favoriteAppsService: FavoriteAppsService
  ) {
  }

  loadData(): Observable<InstitutionGroupModel[]> {
    LoadingService.show();

    if (this.groups.length > 0) {
      LoadingService.hide();
      return of(this.groups);
    }

    if (this.auth.isLoggedIn) {
      return this.getAll();
    } else {
      return this.getGuestGroup();
    }
  }

  getGuestGroup(): Observable<InstitutionGroupModel[]> {
    LoadingService.show();

    return this.http.get<InstitutionGroupModel[]>(`${this.url}/guest`).pipe(
      map(data => {
        this.groups = this.reorderGroupApps(data);
        this.$groups = of(this.groups);
        this.filteredGroups = this.groups;
        this.$filteredGroups = of(this.filteredGroups);
        LoadingService.hide();
        return data
      })
    );
  }

  getAll(): Observable<InstitutionGroupModel[]> {
    LoadingService.show();

    return this.http.get<InstitutionGroupModel[]>(`${this.url}/getAll`).pipe(
      map((data: any) => {
        this.groups = this.reorderGroupApps(data.groups);
        this.$groups = of(this.groups);
        this.filteredGroups = this.groups;
        this.$filteredGroups = of(this.filteredGroups);

        if (data.favouriteApps) {
          this.favoriteAppsService.setFavoriteApps(data.favouriteApps);
        }

        LoadingService.hide();

        return data;
      })
    );
  }

  updateFavoriteApps(appIds: number[]): Observable<any> {
    LoadingService.show();

    return this.http.post(`${this.favoriteAppsUrl}/update`, {
      appIds
    }).pipe(
      tap(() => {
        LoadingService.hide();
      })
    );
  }

  filterGroupsByGroup(group: InstitutionGroupModel): Observable<InstitutionGroupModel[]> {
    this.currentSelectedGroup = group;

    this.filteredGroups = this.groups.filter(group => group.nameSK === this.currentSelectedGroup!.nameSK);

    this.$filteredGroups = of(this.filteredGroups);
    return this.$filteredGroups;
  }

  filterGroupsApps(search: string): Observable<InstitutionGroupModel[]> {
    console.log(search)
    // we want to filter apps withing groups, filter by app codeSK, codeEN, nameSK, nameEN
    if (search === '') {
      this.resetFilteredGroups();
      return this.$filteredGroups;
    }

    search = removeDiacriticsFromString(search);

    this.filteredGroups = this.groups.map(group => {
      const apps = group.apps.filter(app => {
        return removeDiacriticsFromString(app.codeSK).includes(search) ||
          removeDiacriticsFromString(app.codeEN).includes(search) ||
          removeDiacriticsFromString(app.nameSK).includes(search) ||
          removeDiacriticsFromString(app.nameEN).includes(search) ||
          app.keywords.some(keyword => removeDiacriticsFromString(keyword).includes(search));
      });

      return {...group, apps};
    });

    // if there are no apps in group, remove group from filteredGroups
    this.filteredGroups = this.filteredGroups.filter(group => group.apps.length > 0);

    this.$filteredGroups = of(this.filteredGroups);
    return this.$filteredGroups;
  }

  resetFilteredGroups(): void {
    this.filteredGroups = this.groups;
    this.$filteredGroups = of(this.filteredGroups);
    this.currentSelectedGroup = null;
  }

  reorderGroupApps(groups: InstitutionGroupModel[]): InstitutionGroupModel[] {
    // reorder apps in groups by orderNumber and return array of groups with ordered apps
    const reorderedGroups = groups.map(group => {
      group.apps = group.apps.sort((a, b) => a.orderNumber - b.orderNumber);
      return group;
    });

    // reorder groups by orderNumber
    reorderedGroups.sort((a, b) => a.orderNumber - b.orderNumber);

    return reorderedGroups;
  }

}
