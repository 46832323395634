import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, RouterOutlet} from '@angular/router';
import {HttpLoaderFactory, I18nModule} from './i18n/i18n.module';
import {MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateParser} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {PercentageSignTranslateParser} from './i18n/percentage-sign-translate-parser';
import {AppMissingTranslationService} from './i18n/app-missing-translation.service';
import {HeaderComponent} from './components/header/header.component';
import {AppComponent} from './app.component';
import {BrowserModule} from '@angular/platform-browser';
import {routes} from './app.routes';
import {FooterComponent} from './components/footer/footer.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LangSwitcherModule} from './i18n/lang-switcher/lang-switcher.module';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {Button, ButtonDirective} from 'primeng/button';
import {InstitutionSectionComponent} from './components/institution-section/institution-section.component';
import {FavoritesDialogComponent} from './components/favorites-dialog/favorites-dialog.component';
import {InputTextModule} from 'primeng/inputtext';
import {AppItemComponent} from './components/app-item/app-item.component';
import {InputGroupModule} from 'primeng/inputgroup';
import {UserInfoComponent} from './components/user-info/user-info.component';
import {AppThemeModule} from './theme/app-theme.module';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {MessagesModule} from 'primeng/messages';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent
  ],
	imports: [
		BrowserModule,
		CommonModule,
		RouterModule.forRoot(routes),
		I18nModule,
		TranslateModule.forRoot(
			{
				loader: {
					provide: TranslateLoader,
					useFactory: HttpLoaderFactory,
					deps: [HttpClient],
				},
				parser: {
					provide: TranslateParser,
					useClass: PercentageSignTranslateParser,
				},
				missingTranslationHandler: {
					provide: MissingTranslationHandler,
					useExisting: AppMissingTranslationService,
				},
			},
		),
		LangSwitcherModule,
		SidebarComponent,
		Button,
		InstitutionSectionComponent,
		FavoritesDialogComponent,
		InputTextModule,
		AppItemComponent,
		InputGroupModule,
		ButtonDirective,
		UserInfoComponent,
		AppThemeModule,
		ProgressSpinnerModule,
		MessagesModule,
	],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
