import {Component, EventEmitter, Output} from '@angular/core';
import {HeaderActions} from './header-actions';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  @Output() actionClicked: EventEmitter<HeaderActions> = new EventEmitter<HeaderActions>();

  constructor(
    public auth: AuthService,
  ) {
  }

  clickedFavorite() {
    this.actionClicked.emit(HeaderActions.FAVORITES)
  }

  clickedLogin() {
    this.actionClicked.emit(HeaderActions.LOGIN)
  }

}
