export class InstitutionGroupAppModel {
  id: number;
  codeSK: string;
  codeEN: string;
  descriptionSK: string;
  descriptionEN: string;
  nameSK: string;
  nameEN: string;
  url: string;
  icon: string;
  orderNumber: number;
  keywords: string[];
  isFavorite: boolean;
  ssoReady: boolean;
}
