<img src="/img/tuke-logo.png" alt="TUKE Logo" class="header-logo">

<div class="header-text">
  {{'technical-university-header' | translate | uppercase}}
</div>

<div class="actions">

  <p-button *ngIf="auth.isLoggedIn" icon="fa fa-star" [rounded]="true" [text]="true" [outlined]="false" severity="secondary" (onClick)="clickedFavorite()" />

  <app-lang-switcher></app-lang-switcher>

  <app-user-info *ngIf="auth.isLoggedIn"></app-user-info>

  <p-button *ngIf="!auth.isLoggedIn"
            [rounded]="true"
            [text]="true"
            [outlined]="false"
            [label]="'login' | translate"
            severity="secondary" [styleClass]="'login-button'"
            (onClick)="clickedLogin()" />

</div>
