import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {

    constructor(
    ) {
    }

    static show() {
      const loading = document.getElementById('loading');

      if (loading) {
        loading.style.display = 'flex';
      }
    }

    static hide() {
      const loading = document.getElementById('loading');

      if (loading) {
        loading.style.display = 'none';
      }
    }

    static showWithText(text: string) {
      const loading = document.getElementById('loadingText');

      if (loading) {
        loading.innerText = text;
      }

      LoadingService.show();
    }

    static hideText() {
      const loading = document.getElementById('loadingText');

      if (loading) {
        loading.style.display = 'none';
      }
    }

}
