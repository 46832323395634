import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HeaderActions} from '../components/header/header-actions';
import {FavoritesDialogComponent} from '../components/favorites-dialog/favorites-dialog.component';
import {FavoriteAppsService} from '../services/favorite-apps.service';
import {AppGroupService} from '../services/app-group.service';
import {AuthService} from '../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {first, Subscription} from 'rxjs';
import {LoadingService} from '../services/loading.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, OnDestroy {
  isSidebarHidden = false;
  isMobile = false;

  private routeSub: Subscription;
  private redirectUrlAfterLogin: string;

  @ViewChild(FavoritesDialogComponent, {static: false}) favoritesDialog: FavoritesDialogComponent;

  constructor(
    public favoriteAppsService: FavoriteAppsService,
    protected appGroupService: AppGroupService,
    protected auth: AuthService,
    private route: ActivatedRoute
  ) {
    this.extractToken();
  }

  ngOnInit() {

    this.checkResponsivity();

  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkResponsivity();
  }

  extractToken() {

    this.routeSub = this.route.queryParams.subscribe(
      (params) => {
        const devMode = decodeURIComponent(params['development']);

        if (devMode === 'true') {
          if (params['redirectAfterLogin']) {
            this.redirectUrlAfterLogin = decodeURIComponent(params['redirectAfterLogin']);

            // remove params from url after storing them
            this.auth.storeRedirectUrlAfterLogin(this.redirectUrlAfterLogin);

            window.history.replaceState({}, document.title, window.location.pathname);
          }
        }
      },
    );

    // check if in url is action=logout and success=true
    const action = new URLSearchParams(window.location.search).get('action');
    const success = new URLSearchParams(window.location.search).get('success');

    if (action === 'logout' && success === 'true') {
      window.history.replaceState({}, document.title, window.location.pathname);
      this.auth.doAfterLogout();
      this.loadData();
      return;
    }

    const baseToken = new URLSearchParams(window.location.search).get('token');

    if (baseToken) {
      // remove token from url
      window.history.replaceState({}, document.title, window.location.pathname);

      this.auth.extractUserInfo(baseToken ?? '');
    } else {
      this.auth.getUserFromLocalStorage();
    }

    if (this.auth.token) {

      this.auth
        .getSessionInfo()
        .pipe(first())
        .subscribe({
          next: (response) => {
            this.loadData();
          },
          error: (e) => {
            this.auth.removeUserFromLocalStorage();

            this.loadData();
          }
        });

      return;

    }

    this.loadData();

  }

  toggleSidebar() {
    this.isSidebarHidden = !this.isSidebarHidden;
  }

  private checkResponsivity() {
    this.isMobile = window.innerWidth <= 768;
    if (this.isMobile) {
      this.isSidebarHidden = true;
    } else {
      this.isSidebarHidden = false;
    }
  }

  headerActionClicked(action: HeaderActions) {
    switch (action) {
      case HeaderActions.FAVORITES:
        this.showFavoritesDialog();
        break;
      case HeaderActions.LOGIN:
        this.auth.redirectToLogin();
        break;
      default:
        break;
    }
  }

  showFavoritesDialog() {
    this.favoritesDialog.showDialog();
  }

  loadData() {
    const redirectUrl = this.auth.getRedirectUrlAfterLogin();

    if (redirectUrl && this.auth.isLoggedIn) {

      this.auth.redirectTo(redirectUrl);

      this.auth.removeRedirectUrlAfterLogin();

      return;
    }

    this.appGroupService.loadData().subscribe();
  }
}
