import { Component } from '@angular/core';
import {AppItemComponent} from '../components/app-item/app-item.component';
import {AsyncPipe, NgForOf, NgIf} from '@angular/common';
import {ButtonDirective} from 'primeng/button';
import {FavoritesDialogComponent} from '../components/favorites-dialog/favorites-dialog.component';
import {InputGroupModule} from 'primeng/inputgroup';
import {InputTextModule} from 'primeng/inputtext';
import {InstitutionSectionComponent} from '../components/institution-section/institution-section.component';
import {MessagesModule} from 'primeng/messages';
import {MessageService, PrimeTemplate} from 'primeng/api';
import {SidebarComponent} from '../components/sidebar/sidebar.component';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ExternalAuthenticationRequest} from '../models/external-authentication-request.model';
import {FormsModule} from '@angular/forms';
import {AuthService} from '../services/auth.service';
import {first} from 'rxjs';
import {Router} from '@angular/router';
import {ToastModule} from 'primeng/toast';
import {AppTranslationService} from '../i18n/app-translation.service';
import {_} from '../i18n/translate-placeholder';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    AppItemComponent,
    AsyncPipe,
    ButtonDirective,
    FavoritesDialogComponent,
    InputGroupModule,
    InputTextModule,
    InstitutionSectionComponent,
    MessagesModule,
    NgForOf,
    NgIf,
    PrimeTemplate,
    SidebarComponent,
    TranslateModule,
    FormsModule,
    ToastModule
  ],
  providers: [
    MessageService,
    AuthService,
    Router,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  externalAuthenticationRequest = new ExternalAuthenticationRequest();

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private translateService: AppTranslationService
  ) {
  }

  clickedSubmit(e: Event) {
    e.preventDefault();

    this.authService
      .externalLogin(this.externalAuthenticationRequest)
      .pipe(first())
      .subscribe({
        next: () => {
          this.router.navigate(['/dashboard']);

          this.messageService.add({ severity: 'success', summary: 'Success', detail: _('Login success') });
        },
        error: () => {
          console.log('Login failed');
          this.messageService.add({ severity: 'error', summary: 'Error', detail: _('Login failed') });
        }
      })
  }

}
