<div class="card flex justify-content-center" *ngIf="auth?.loggedUserData?.user">

  <div class="card-item--header flex gap-2 cursor-pointer">

    <span (click)="menu.toggle($event)">
      {{auth.loggedUserData?.user?.name}} {{auth.loggedUserData?.user?.surname}}
    </span>

    <i class="fa"
       [ngClass]="{'fa-angle-down': !menu.visible, 'fa-angle-up': menu.visible}"
       (click)="menu.toggle($event)"
    ></i>

  </div>

  <p-tieredMenu #menu [model]="items" [popup]="true" />

</div>
