<nav class="sidebar">

  <div class="sidebar--items">

    <app-sidebar-item
      *ngFor="let group of appGroupService.$groups | async; let i = index"
      [group]="group" [index]="i"
      (clicked)="clickedSidebarItem($event)"></app-sidebar-item>

  </div>

<!--  <button (click)="toggleSidebar()">Toggle Sidebar</button>-->
</nav>
