// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.dev.ts` with `environmentDev.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const SYSTEM_NAME = 'PORTAL';

export const environment = {
  production: false,
  useHttps: false,
  isLocal: false,
  systemName: SYSTEM_NAME,
  organizationName: 'Univerzitná knižnica',
  organizationWebsiteUrl: 'http://www.lib.tuke.sk/',
  organizationSupportEmail: 'kniznica@tuke.sk',
  organizationSupportPhone: '+421 55 602 7700',
  availableLanguages: ['sk', 'en'],
  defaultLocale: 'sk',
  API_URL: `https://api.portal.dev.msweb.tuke.sk/api`,
  BASE_API_URL: `https://api.portal.dev.msweb.tuke.sk`,
  AUTH_URL: `https://api.auth.portal.dev.msweb.tuke.sk/api`,
  userRoles: {
    SuperAdmin: 'SUPERADMIN',
    All: [
      'SUPERADMIN',
    ],
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
