import {NgModule} from '@angular/core';
import { LangSwitcherModule } from './lang-switcher/lang-switcher.module';
import {AppMissingTranslationService} from './app-missing-translation.service';
import {HttpClient} from '@angular/common/http';
import {AppTranslationService} from './app-translation.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

@NgModule({
    imports: [
        LangSwitcherModule,
    ],
    declarations: [
    ],
    exports: [
    ],
    providers: [
        AppTranslationService,
        AppMissingTranslationService,
    ]
})

export class I18nModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        '/langs/',
        '.json',
    );
}
