import { Pipe, PipeTransform } from '@angular/core';
import {AppTranslationService} from '../i18n/app-translation.service';

@Pipe({
  name: 'localizedParam',
  standalone: true,
  pure: false
})
export class LocalizedParamPipe implements PipeTransform {

  constructor(private translationService: AppTranslationService) {}

  transform(value: any, fieldPrefix: string): string {
    const currentLanguage = this.translationService.currentLanguage;
    const languageSuffix = currentLanguage.toUpperCase(); // 'SK' or 'EN', assuming languages are 'sk' and 'en'

    const fieldName = `${fieldPrefix}${languageSuffix}`;
    return value[fieldName] || ''; // Return the localized field or an empty string if not found
  }

}
