<div class="lang-switcher-pos" (outsideClick)="expanded = false" outsideClick>

  <div class="selected" *ngIf="currentLanguage" (click)="clickedIcon($event)">

    <div class="icon-lang">
      <img [src]="currentLanguage.icon" [alt]="currentLanguage.name" />
    </div>

<!--    <div class="arrow">-->
<!--      <i class="fal fa-arrow-down" [class.arrow-icon&#45;&#45;rotated]="expanded"></i>-->
<!--    </div>-->

  </div>

  <div class="available-languages" [class.available-languages--hidden]="!expanded">

    <div class="lang" *ngFor="let lang of availableLanguages" (click)="selectLanguage(lang)">
      <img [src]="lang.icon" [alt]="lang.name" />
    </div>

  </div>

</div>
